
/* Customize the scrollbar track */
::-webkit-scrollbar {
  width: 12px; /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #888; /* Set the color of the thumb */
  border-radius: 6px; /* Add rounded corners to the thumb */
}

/* Customize the scrollbar track when hovering over it */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Customize the scrollbar track when it's in an active or clicked state */
::-webkit-scrollbar-thumb:active {
  background: #333;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body [src^="data:image"] {
  display: initial !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#intro-panel {
  /* background: linear-gradient(-45deg,#0c9ed4 0%,#51c9f5 100%); */
  /* background: linear-gradient(-45deg,#317f9f 0%,#a7c0d9 100%); */
  /* background: linear-gradient(-45deg,#1f738dc7 0%,#a7c0d900 100%);
  width: 100%; */
  background: linear-gradient(-45deg,#317f9f 0%,#a7c0d9 100%);
  opacity: 0.9;
  width: 100%;
}

header {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: space-between;
  padding: 0 240px;
  align-items: center;
}
header img {
  width: 210px;
  cursor: pointer;
}
.page-links a.menu-item {
  color: black;
  padding: 10px 20px;
  font-weight: bold;
  transition: 0.5s;
}
.page-links a.menu-item:hover {
  text-decoration: none;
  color: rgb(26, 107, 199);
  transition: 0.5s;
}


#form-search {
  background-color: rgba(115,186,221,.35);
  box-shadow: none;
  border: medium;
  border-radius: 30px;
  box-shadow: 0 0 0;
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 300;
  height: 50px;
  line-height: 1.42857;
  padding: 6px 20px;
  vertical-align: middle;
  transition: background-color .2s;
}
#search-form {
  position: relative;
  display: inline-block;
  color: white;
  background-color: #ffffff1f;
  padding: 15px 80px;
  border-radius: 50px;
}
#search-form input {
  color: white!important;
  background-color: transparent!important;
}
#search-form input:focus {
  background-color: transparent!important;
}
.search-field {
  float: left;
  margin-right: 20px!important;
  /* box-shadow: none;
  border-radius: 30px;
  border: 0!important;
  box-shadow: 0 0 0;
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.42857;
  vertical-align: middle;
  transition: background-color .2s;
  float: left;
  box-sizing: border-box;
  min-width: 200px;
  margin-right: 20px; */
}
.search-field label {
  color: white!important;
  margin-left: 8px;
  margin-top: -5px;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
  top: 10px!important;
}
.search-field input {
  
}
/* label+.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  margin: 0;
  height: 39px;
  padding: 0 20px;
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 1px solid rgb(0, 0, 0)!important;
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 2px solid rgb(0, 0, 0)!important;
}
.css-v4u5dn-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid #ddd!important;
  border-radius: 10px!important;
} */
#search-form .MuiInput-underline:after {
  border-radius: 10px!important;
  border-bottom: 2px solid white!important;
  height: 60px;
}
label+.css-v4u5dn-MuiInputBase-root-MuiInput-root {
  margin: 0;
  height: 39px;
  padding: 0 20px;
}
#search-form .MuiInput-underline:before {
  border-bottom: 1px solid #ddd!important;
  border-radius: 10px!important;
}
#btn-search {
  /* border: 1px solid #328eb2; */
  border: 1px solid white;
  padding: 12px!important;
  line-height: 30px;
  display: block;
  opacity: .85;
  background: transparent;
  color: #666;
  transition: all .2s;
  box-shadow: 0 0 0;  
  background-color: #ffffffed;
}
#image-slider-box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 150%;
  position: absolute;
  opacity: 1;
  left: 100px;
  transform: skew(-16deg, 4deg);
}
#image-slider1 {
  display: flex;
  flex-direction: column;
  width: 344px;
  position: relative;
}
#image-slider2 {
  display: flex;
  flex-direction: column;
  width: 720px;
  position: relative;
  margin-left: -40px;
}
#home-card {
  padding: 120px 40px 80px 40px!important;
  color: white;
  border: 0;
  text-align: center;
  background: transparent;
}
#home-card h1 {
  padding: 50px;
  letter-spacing: 10px;
}
#home-card p {
  padding: 20px;
  font-size: 18px;
}
#domain-box {
  padding: 20px 0;
}
.domain-item {
  padding: 10px 0;
}
.domain-title {
  text-decoration: none;
  color: #508fab;
}
.domain-title:hover {
  text-decoration: none;
  color: #116083;
}
.domain-description {
  letter-spacing: 0.5px;
}
.bg1 {
  background-color: transparent;
  background-image: linear-gradient(110deg,#f9f9fb 32%,#25399314 0);
}
.bg2 {
  background-color: transparent;
  background-image: linear-gradient(110deg,hsla(0,0%,100%,0) 64%,#25399314 0);
  opacity: 1;
  transition: background .3s,border-radius .3s,opacity .3s;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  min-height: calc(100vh - 90px);
}
#domain-section {
  padding: 30px 20px;
}
.city-item {
  border: 1px solid red;
  margin: 5px!important;
  display: inline-block;
}
.city-item a {
  font-size: 14px!important;
  margin: 0;
  padding: 10px 15px;
  color: #517397;
}
#city-section .MuiButtonBase-root {
  padding: 0!important;
}
.city-item a:hover {
  text-decoration: none;
}
#domain-section p {
  letter-spacing: 0.5px;
  line-height: 1.7;
  font-size: 18px;
  position: relative;
}
#domain-section button {
  position: absolute;
  right: 0;
}
.modal-header {
  padding: 20px 30px;
  background-color: #525f6a;
  color: white;
}
.btn-load {
  padding: 5px!important;
  border-radius: 100%!important;
  min-width: auto!important;
  margin-top: -5px!important;
  margin-left: 10px!important;
}
.MuiSvgIcon-root.active {
  animation: rotate 2s linear infinite!important; /* Apply the animation */
}
.modal-header button, .close:hover {
  color: white;
}
.modal-body {
  padding: 25px 35px;
  letter-spacing: 0.5px;
  height: calc(100vh - 300px);
  overflow-y: scroll;
}

.modal-footer button:hover {
  background-color: #525f6a;
  border: 1px solid #525f6a;
}
.modal-footer button.btn-success {
  background-color: #525f6a;
  border: 1px solid #525f6a;
}
.modal-footer button.btn-success:focus {
  background-color: #525f6a;
  border: 1px solid #525f6a;
  box-shadow:  0 0 0;
}

.domain-title h3 {
  display: inline-block;
}
#icon-ai {
  height: 30px;
}
#icon-ai.loading {
  animation: rotate 2s linear infinite; /* Apply the animation */
}

#ai-content-box {
  padding: 10px 30px;
}
#ai-content-box  h1 {
  text-align: center;
}
#ai-content-box p {
  margin-bottom: 0.2rem;
}
#btn-return {
  position: absolute;
  right: 30px;
}
#search-form button span {
  display: initial!important;
}

#about-box {
  padding: 50px;
}
strong {
  color: #555;
  font-weight: 600;
}
#about-box >div > p > strong {
  font-size: 1.5rem;
  font-weight: 700;
}

#properties {
  text-align: center;
  margin: 10px;
}
#properties span {
  margin-right: 50px;
  font-size: 1rem;
}
#description {
  text-align: left;
  margin-top: 15px;
  width: 100%;
  padding: 20px 50px;
  font-size: 1.1rem;
  position: relative;
}
#description p:first-child {
  text-indent: 60px;
}
#description p {
  text-indent: 0;
}
#text-section {
  padding-top: 15px;
}
#description button {
  position: absolute;
  top: -15px;
  left: 50px;
  display: flex;
  align-items: flex-start;
}
#description p {
  text-align: left;
}
#speed-page h1 {
  margin-top: 30px;
  text-align: center;
}
.btn-load-ai {
  padding: 5px!important;
  min-width: auto!important;
  border-radius: 100%!important;
}
footer {
  background-color: rgb(208 210 223);
  text-align: center;
  color: #1a1a1a;
  padding: 30px;
  height: 90px;
  color: #4b4b5d;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
}
footer .page-links a {
  padding: 10px;
  color: #105cad;
  transition: 0.5s;
}
.page-links a:hover {
  text-decoration: none;
  color: #0068d7;
  transition: 0.5s;
}
#btn-return {
  position: absolute;
  right: 0;
  top: 20px;
}

#cities-section h3 {
  margin-bottom: 20px;
}
#city-section {
  padding: 0;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  max-height: 260px;
  overflow-y: scroll;
}
#city-section-box {
  padding: 20px 30px;
  margin: 10px 50px;
  border: 1px solid rgb(187, 187, 207);
  border-radius: 15px;
  text-align: center;
}
#city-select-section .MuiAutocomplete-root {
  margin: 0 auto;
  max-width: 500px;
  min-width: 300px;
}

#contact-form {
  display: flex;
  flex-direction: column;
}

#contact-form .MuiFormControl-root {
  margin-top: 30px;
}

#btn-contact {
  padding: 0;
}
#btn-contact a {
  color: white;
  width: 100%;
  padding: 20px;
}
#btn-contact a:hover {
  text-decoration: none!important;
}
/* #city-select-section .MuiFormLabel-root {
  margin-left: 15px;
}
#city-select-section .MuiFormLabel-root.Mui-focused {
  margin-left: 0!important;
} */
.menu-toggle {
  border: 0;
  padding: 10px 15px;
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: white;
  display: none;
  border: 1px solid #eee;
}

@keyframes rotate {
  /* Define the animation */
  from {
      transform: rotate(360deg); /* Start with no rotation */
  }
  to {
      transform: rotate(0deg); /* End with a full 360-degree rotation */
  }
}

@media (min-width: 1300px) {

  #description {
    width: 1100px;
    margin: 0 auto;
  }
}

@media (max-width: 1000px) {
  #search-form {
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
  #search-form .search-field {
    width: 100%;
  }

  #city-select-section .MuiAutocomplete-root {
    margin: 0 50px;
  }
}

@media (max-width: 850px) {
  #domain-section button {
    top: 10px;
  }
  #ai-content-box {
    padding-top: 10px;
  }

  #city-select-section .MuiAutocomplete-root {
    margin: 0 50px;
  }

  header {
    padding: 0px 30px;
  }

  header .page-links {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  header .page-links.menu-open {
    height: 100vh;
    background-color: white;
    z-index: 100;
    opacity: 0.95;
    padding-top: 80px;
  }

  header .page-links .menu-toggle {
    display: block;
  }
  

  header .page-links .menu-item {
    display: none;
  }
  header .page-links.menu-open .menu-item {
    display: block;
    padding: 15px 20px;
    font-size: 24px;
    text-align: center;
  }

  header {
    padding: 0px 30px;
  }

  footer {
    display: flex;
    flex-direction: column;
  }
}
